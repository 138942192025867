import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { RequesterService } from '../services/requester.service';
import { StorageService } from '../services/storage.service';

interface User {
	id: number;
	name: string;
	email: string;
}

@Injectable({
	providedIn: 'root',
})
export class UserModelService {

	userId;
	userName;
	userEmail;
	user: User;
	name;
	email;
	sub = new Subject();

	constructor (
		private requester: RequesterService,
		private router: Router,
		private storage: StorageService,
	) {
	}

	async createUser (data) {
		if (!data.id || !data.name) {
			throw new Error('Wrong data for user');
		}

		await this.storage.set('user', { id: data.id, name: data.name, email: data.email });

		this.userId = data.id;
		this.userName = data.name;
		this.userEmail = data.email;

		this.user = {
			id:    data.id,
			name:  data.name,
			email: data.email
		};

		this.sub.next({
			id:    data.id,
			name:  data.name,
			email: data.email,
		});

		return {
			id:    this.userId,
			name:  this.userName,
			email: this.userEmail,
		};
	}

	updateUser ({ name }) {
		return this.requester.updateUser({ name })
		.then(() => {
			return this.storage.get('user');
		})
		.then((user: { name: string, email: string }) => {
			user.name = name;

			return this.createUser(user);
		});
	}

	changeUser () {
		return this.sub;
	}

	async getUser (): Promise<User> {
		if (this.user) {
			return this.user;
		}

		try {
			const rawUser = await this.storage.get('user');
			if (rawUser) {
				return this.createUser(rawUser);
			}
		} catch (e) {
			try {
				await this.deleteUser();
				const serverUser = await this.requester.getUser();

				return this.createUser(serverUser);
			} catch (err) {
				if (err && err.code === 'NEED_AUTH') {
					await this.router.navigate(['login']);
				}
			}
		}
	}

	async getUserFromCache () {
		try {
			const user = await this.storage.get('user');
			if (user) {
				this.sub.next({
					name:  user.name,
					email: user.email,
				});
			}

			return user;
		} catch (err) {
			console.log('get user', err);
		}
	}

	deleteUser () {
		this.user = null;
		this.name = null;
		this.email = null;

		return this.storage.del('user')
		.then(() => {

			this.sub.next(null);
		});
	}

	async getUserId () {
		const user = await this.getUser();

		return user.id;
	}

	async createSub (sub) {
		return this.requester.postUserSub(await this.getUserId(), sub);
	}
}
